import axios from "axios";
import { request } from "@/utils/request";
// 查询数据
export function selectmsgWeb(data) {
  return request({
    url: `/admin/msgWeb/select`,
    method: "POST",
    data,
  });
}

// 新增数据
export function addmsgWeb(data) {
  return request({
    url: `/admin/msgWeb/add`,
    method: "POST",
    data,
  });
}

/* 修改数据 */
export function updatemsgWeb(data) {
  return request({
    url: `/admin/msgWeb/update`,
    method: "POST",
    data,
  });
}

// 删除数据
export function deletemsgWeb(data) {
  return request({
    url: `/admin/msgWeb/delete`,
    method: "POST",
    data,
  });
}

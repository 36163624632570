export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    { label: "通知内容", prop: "message" },
    {
      label: "创建时间",
      prop: "createTime",
      width: 200,
    },
    {
      label: "操作",
      prop: "operation",
      width: 200,
      slots: "operation",
    },
  ];
}
